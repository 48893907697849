import PropTypes from "prop-types";
import React from "react";

import Header from "./header";

import { FaEnvelopeOpen, FaFlag, FaPhone, FaLocationArrow, FaUniversity} from 'react-icons/fa'

import locationMap from "../images/location@2x.png";
import provoetLogo from "../images/provoet_logo.jpeg";

function Layout({ location, children }) {
  return (
    <div className="flex flex-col min-h-screen font-sans font-light text-gray-900">

      <Header location={location}/>

      <main className="flex-1 w-full max-w-4xl px-4 py-8 mx-auto md:py-16 sm:px-8">
        {children}
      </main>

      <footer className="">
        <div className="bg-blue">
          <nav className="max-w-4xl p-8 mx-auto text-white text-xl font-normal text-center">
              Erkend pedicure in Akkrum
          </nav>
        </div>
        <div className="bg-gray">
          <div className="max-w-4xl px-4 py-8 mx-auto text-sm grid grid-cols-3 gap-8 w-full">
            <div>
              <h3 className="text-xl font-medium pb-4"><FaFlag className="inline mr-4 text-blue" />Contact</h3>
              <p itemProp="address" itemScope="" itemType="http://schema.org/PostalAddress">
                  <span itemProp="streetAddress">Kanadeeskestrjitte 53</span>
                  <br />
                  <span className="whitespace-nowrap"><span itemProp="postalCode">8491 BC</span>, <span itemProp="addressLocality">Akkrum</span></span>
              </p>
              <p>
                  <span className="whitespace-nowrap"><FaPhone className="inline mr-2 " /><span className="" itemProp="telephone">0566-785163</span></span>
                  <br />
                  <span className="whitespace-nowrap"><FaEnvelopeOpen className="inline mr-2" /><span itemProp="email">info@voetsalonakkrum.nl</span></span>
              </p>
            </div>
            <div>
              <h3 className="text-xl font-medium pb-4"><FaLocationArrow className="inline mr-4 text-blue" />Locatie</h3>
              <img
                alt="Cat and human sitting on a couch"
                className="mx-auto"
                src={locationMap}
              />
            </div>
            <div>
              <h3 className="text-xl font-medium pb-4"><FaUniversity className="inline mr-4 text-blue" />Lid van ProVoet</h3>
              <img
                alt="Cat and human sitting on a couch"
                className="mx-auto"
                src={provoetLogo}
              />
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.any
};

export default Layout;
