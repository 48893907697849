import { graphql, useStaticQuery, Link } from "gatsby";
import React from "react";
import PropTypes from 'prop-types';

import { FaEnvelope, FaPhone} from 'react-icons/fa'
// import styled from 'styled-components'
import logo from "../images/logo.png";

const MenuButton = ({active, ...props}) => {
  return <Link
    className={`px-4 py-6 ${active ? 'text-white bg-blue' : 'text-grey'}`}
    {...props}
  / >
}
MenuButton.propTypes = {
    active: PropTypes.any,
};

const Header = ({ location }) => {
  const { site } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <header>
      <div className="bg-gray text-blue">
        <div className="flex flex-row justify-end max-w-4xl mx-auto p-4 text-sm space-x-5">
          <div><FaEnvelope className="inline mr-2" />info@voetsalonakkrum.nl</div>
          <div><FaPhone className="inline mr-2" />0566-785163</div>
        </div>
      </div>
      <div className="bg-white border-2 border-solid border-gray">
        <div className="max-w-4xl mx-auto flex">
          <a itemProp="url" href="http://www.voetsalonakkrum.nl/" className="w-0 sm:w-28" title={site.siteMetadata.title}>
            <img
              alt="Voetsalon Akkrum"
              className="absolute w-14 sm:w-28 -mt-12 sm:-mt-6 pl-4 sm:pl-0 "
              src={logo}
            />
          </a>
          <span itemProp="name" className="flex-grow text-2xl sm:text-3xl md:text-4xl pl-4 sm:pl-0 font-semibold flex items-center align-middle text-neutral-600">{site.siteMetadata.title}</span>
          
          <nav
            className={`flex flex-row justify-end text-sm`}
          >
            {[
              {
                route: `/`,
                title: `Home`,
              },
              {
                route: `/locatie`,
                title: `Locatie`,
              },
              {
                route: `/contact`,
                title: `Contact`,
              },
            ].map((link) => (

              <MenuButton
                active={location.pathname==link.route}
                key={link.title}
                to={link.route}
              >{link.title}</MenuButton>
            ))}
          </nav>
        </div>
      </div>
    </header>
  );
}
Header.propTypes = {
  location: PropTypes.any
};

export default Header;
